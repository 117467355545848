import React from 'react'
import { ITeaserOverlayStyle } from '../../../../../types/cmsPlacement/LXTeaser'
import styled from '@mui/material/styles/styled'
import { StyledTypography } from '@components/UI/Typography'
import { cmsModuleLateralMargin, teaserBackGroundColorToBg, teaserOverlayStyleToColor } from '@utils/placements'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import { isCMCollection } from '@typesApp/cmsPlacement/Placement'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'

const WrapperCollectionText = styled('div', {
  name: 'CollectionText',
  slot: 'Wrapper',
  shouldForwardProp: prop =>
    prop !== 'backgroundColor' &&
    prop !== 'textOverlayStyle' &&
    prop !== 'paddingBottomEnabled' &&
    prop !== 'marginLateral',
})<{
  backgroundColor: string
  textOverlayStyle: ITeaserOverlayStyle
  paddingBottomEnabled?: boolean
  marginLateral: boolean
}>(({ backgroundColor, textOverlayStyle, paddingBottomEnabled, marginLateral, theme }) => ({
  ...teaserBackGroundColorToBg(backgroundColor, theme),
  ...teaserOverlayStyleToColor(theme, textOverlayStyle),
  ...cmsModuleLateralMargin(marginLateral, theme),

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(0, 4, paddingBottomEnabled ? 6 : 0),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(4),
    padding: theme.spacing(0, 4, paddingBottomEnabled ? 6 : 0),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 44, paddingBottomEnabled ? 6 : 0),
  },
}))

const CollectionTitle = styled(StyledTypography, {
  name: 'CollectionText',
  slot: 'Title',
})(() => ({
  fontSize: 24,
}))

const CollectionText = styled('div', {
  name: 'CollectionText',
  slot: 'Text',
})(() => ({
  fontSize: 16,

  p: {
    margin: '0',
  },
}))

const CollectionContentText: React.FC<{
  item: ICMCollection | ICMPlaceholder
  backgroundColor: string
  marginLateral: boolean
}> = ({ item, backgroundColor, marginLateral }) => {
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()

  if (!item) return null

  const collectionTitle = item.collectionTitle || ''
  const collectionSubTitle = item.collectionSubTitle || ''
  const collectionText = item.collectionText || ''
  const collectionTextOverlayStyle = isCMCollection(item)
    ? item.collectionTextOverlayStyle
      ? item.collectionTextOverlayStyle
      : ''
    : ''
  const isProductBanner = ['dcw-products'].includes(item.viewtype!)
  const collectionBg = isProductBanner ? 'bg-light-secondary' : backgroundColor

  const paddingBottomEnabled = !!(collectionTitle || collectionSubTitle || collectionText)
  return (
    <WrapperCollectionText
      backgroundColor={collectionBg}
      textOverlayStyle={collectionTextOverlayStyle}
      paddingBottomEnabled={paddingBottomEnabled}
      marginLateral={marginLateral}
    >
      {collectionTitle && (
        <CollectionTitle fontWeight={'bold'} lineHeight={'1.4'} component={'p'} isUppercased>
          {collectionTitle}
        </CollectionTitle>
      )}
      {collectionSubTitle && (
        <StyledTypography fontSize={16} component={'p'}>
          {collectionSubTitle}
        </StyledTypography>
      )}
      {collectionText && (
        <CollectionText dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(collectionText) }} />
      )}
    </WrapperCollectionText>
  )
}

export default CollectionContentText
