import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacementItem, IPlacement } from '@typesApp/cmsPlacement/Placement'
import { isCMExternalPage } from '@typesApp/teaser'

export const isShoppableTeaser = (teaser: ILXTeaser | undefined): boolean => {
  if (!teaser) return false
  const hotZones = teaser.hotZones
  return Array.isArray(hotZones) && hotZones.length > 0
}

export const hasOverlay = (item: ILXTeaser | undefined): boolean => {
  return Boolean(item?.teaserOverlay2Style?.includes('shadow'))
}

export const getToLink = (item: ILXTeaser | undefined): string => {
  const target = item?.teaserLXCallToActionSettings?.[0]?.target

  if (target?.formattedUrl) {
    return target.formattedUrl
  }

  if (target && isCMExternalPage(target as IPlacementItem)) {
    const externalPageTarget = target as ICMExternalPage

    return externalPageTarget.externalId ?? ''
  }

  return ''
}

export const hasTermsAndConditions = (item: ILXTeaser | undefined): boolean =>
  Boolean(item?.targetsTermsAndConditions?.target)

/**
 * Utility function to find a placement by name.
 * @param placements - Array of placements to search within.
 * @param name - The name to search for.
 * @returns The matching placement.
 */
export const findPlacementByName = (placements: IPlacement[] = [], name: string): IPlacement | undefined => {
  return placements.find(placement => placement.name === name)
}

/**
 * Utility function to search deeply for a given type in an object.
 * @param data - The object to search through.
 * @param typeToFind - The type value to search for.
 * @returns The first object containing the matching type, or undefined if not found.
 */
export const findFirstPlacementByType = <T>(data: unknown, typeToFind: string): T | undefined => {
  if (data && typeof data === 'object' && 'type' in data && data.type === typeToFind) {
    return data as T
  }

  if (Array.isArray(data)) {
    for (const item of data) {
      const result = findFirstPlacementByType(item, typeToFind)
      if (result) {
        return result as T
      }
    }
  }

  if (typeof data === 'object' && data !== null) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const result = findFirstPlacementByType(data[key], typeToFind)
        if (result) {
          return result as T
        }
      }
    }
  }

  return undefined
}
