import { StyledTypography } from '@components/UI/Typography'
import styled from '@mui/material/styles/styled'
import { PropsWithChildren } from 'react'
import {
  ITeaserOverlaySettings,
  ITeaserOverlayStyle,
  ITeaserOverlayTextAlign,
} from '../../../../../types/cmsPlacement/LXTeaser'
import {
  getRatioBanner,
  overLaySettingsToVerticalCSS,
  overLayTextAlignToCSS,
  teaserOverlayStyleToColor,
} from '../../../../../utils/placements'
import CmsBroadContentWrapper from '../../../CmsComponents/CmsBroadContentWrapper'
import CmsTeaserDetailText from '../../../CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '../../../CmsComponents/CmsTeaserTitle'

export const StyledWrapperTextModule = styled(CmsBroadContentWrapper, {
  name: 'TextModule',
  slot: 'Content',
  shouldForwardProp: prop => prop !== 'overlayTextAlign' && prop !== 'overlaySettings' && prop !== 'teaserOverlayStyle',
})<
  PropsWithChildren<{
    overlaySettings?: ITeaserOverlaySettings
    overlayTextAlign?: ITeaserOverlayTextAlign
    teaserOverlayStyle?: ITeaserOverlayStyle
  }>
>(({ overlaySettings, overlayTextAlign, teaserOverlayStyle, theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    ...overLaySettingsToVerticalCSS(overlaySettings || ''),
  },

  '& > *': {
    ...overLayTextAlignToCSS(overlayTextAlign),
    ...teaserOverlayStyleToColor(theme, teaserOverlayStyle),
  },
}))

export const ContentTextModule = styled('article', {
  name: 'TextModule',
  slot: 'Content',
  shouldForwardProp: prop => prop !== 'removePaddingTop',
})<{ removePaddingTop?: boolean }>(({ removePaddingTop, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(removePaddingTop ? 0 : 10, 0, 10),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 16, 0, 16),
  },
}))

export const StyledTextModuleContainer = styled('article', {
  name: 'TextModule',
  slot: 'Container',
})(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const PreTitleTextModule = styled(StyledTypography, {
  name: 'TextModules',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const StyledTitleTextModule = styled(CmsTeaserTitle, {
  name: 'TextModules',
  slot: 'Title',
})(({}) => ({
  textTransform: 'none',
}))

export const StyledTitleArticle = styled(StyledTitleTextModule)<{
  textAlign: ITeaserOverlayTextAlign
}>(({ textAlign }) => ({
  width: '100%',
  textAlign,
}))

export const TextTextModule = styled(CmsTeaserDetailText, {
  name: 'TextModules',
  slot: 'Text',
})(() => ({
  width: '100%',
}))

export const StyledDetailTextArticle = styled(TextTextModule, {
  name: 'TextModules',
  slot: 'Article',
})(({ theme }) => ({
  width: '100%',
  textAlign: 'left',

  // Homepage h1
  h1: {
    borderTop: '1px solid',
    borderBottom: '1px solid',
    margin: theme.spacing(0, 12),
    padding: theme.spacing(12, 0),
    textAlign: 'center',
    ...theme.typography.h5,
  },
}))

export const CtaContentTextModule = styled('div', {
  name: 'TextModules',
  slot: 'CtaContent',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: theme.spacing(2),
  justifyContent: 'inherit',
  gap: theme.spacing(4),
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const IconContentTextModule = styled('div', {
  name: 'TextModules',
  slot: 'IconContent',
})(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  svg: {
    height: 48,
    width: 48,
  },
}))

export const StyledWrapperTextModuleMedia = styled('div', {
  name: 'WrapperTextModule',
  slot: 'Media',
})(({ theme }) => ({
  ...getRatioBanner('full-width-banner', theme),
}))
